import React from "react";
import Lead from "../components/sections/lead";
import About from "../components/sections/about";
import BrandsShowcase from "../components/sections/brandsShowcase";
import Career from "../components/sections/career";
import Feedback from "../components/sections/feedback";
import Footer from "../components/sections/footer";
import indexLead from "../images/lead-bg@1x.jpg";
import indexMobileLead from "../images/lead-bg--mobile@1x.jpg";
import leadVideo from "../video/homepage.mp4";
import Layout from "../components/sections/layout";
import {t} from "i18next";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

// markup
const IndexPage = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <Helmet>
        <html lang='ru' />
        <title>{t('lead.seo.mainPage')}</title>
      </Helmet>
      <Lead
        title={t('lead.mainTitle.mainText')}
        leadMobileImage={indexMobileLead}
        leadImage={indexLead}
        leadVideo={leadVideo}
        videoClass={`lead__video-wrapper--big`}
      />
      <About />
      <BrandsShowcase />
      <Career />
      <Feedback />
      <Footer />
    </Layout>
  );
};

export const Head = () => (
  <title>Главная</title>
)

export default IndexPage;
